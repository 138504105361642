import React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, ExcelExport, Inject, Toolbar, Resize } from '@syncfusion/ej2-react-grids';
import { ptrfGrid, ptrfData } from '../data/dummy';
import { Header } from '../components';

const Ptrf = () => {
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['ExcelExport']; // Add other desired options for the toolbar if needed
  const customAttributes = { class: 'customcss' };

  // Define headerStyle object here
  const headerStyle = {
    backgroundColor: '#c0c0c0',
    color: 'rgb(0, 0, 0)',
    paddingLeft: '10px',
  };

  return (
    <div className="m-2 md:m-3 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="PTRF" title="" />
      <GridComponent
        id="replenishmentGrid"
        dataSource={ptrfData} // Use ptrfData for the dataSource
        enableHover={false}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        allowTextWrap={false}
        allowResizing={false}
        allowScrolling={true}
        width="auto"
      >
        <ColumnsDirective>
          {ptrfGrid.map((item, index) => (
            <ColumnDirective
              key={index}
              {...item}
              header={index === 0 ? headerStyle : undefined}
              customAttributes={customAttributes}
              
            />
          ))}
        </ColumnsDirective>
        <Inject services={[Toolbar, ExcelExport, Resize]} />
      </GridComponent>
    </div>
  );
};

export default Ptrf;
